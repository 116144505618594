<template>
  <Dialog
    class="with-footer-gradient campaign-form-dialog"
    :visible="visible"
    dismissableMask
    modal
    :pt="{
      root: 'p-dialog-maximized',
      footer: {
        class: 'block',
        style: {
          'padding-top': '1.5rem',
        }
      }
    }"
    :showHeader="false"
    @update:visible="onToggle"
  >
    <ScrollContainer
      ref="dialogBody"
      class="wizard-container pt-3"
    >
      <!-- Step 1 -->
      <Card v-if="activeStep === 1">
        <template #content>
          <WizardStepHeader
            :stepNumber="1"
            title="Campaign, Target, and Categories"
          />

          <Divider />

          <VeeForm
            v-slot="{ handleSubmit }"
            as="div"
            :validationSchema="stepOneSchema"
            @invalidSubmit="onInvalidSubmit"
          >
            <form
              id="campaign-form"
              @submit.prevent="handleSubmit($event, onSubmitStepOne)"
            >
              <BaseInput
                v-model="stepOneForm.name"
                fieldId="name"
                fieldName="name"
                fieldLabel="Ad Campaign Name"
              />

              <BaseFieldContainer>
                <div class="mb-2 font-bold">Target Gender</div>
                <GenderInput
                  v-model="stepOneForm.targetGender"
                />
              </BaseFieldContainer>

              <Divider />

              <BaseFieldContainer>
                <div class="mb-2 font-bold">Target Age</div>
                <TargetAgeInput
                  v-model="stepOneForm.targetAgeRanges"
                />
              </BaseFieldContainer>

              <BaseFieldContainer>
                <div class="mb-2 font-bold">Geographic Targeting</div>
                <GeographicTargetMessage />
              </BaseFieldContainer>

              <Divider />

              <CategoryInput
                fieldLabel="Podcast Categories"
                fieldName="categories"
                :categoryOptions="formattedCategoryOptions"
                v-model="stepOneForm.categories"
              >
                <template #helperText>
                  <p class="mt-0 mb-3">
                    Choose all show genres you would like to reach.
                  </p>
                  <p class="mt-0 mb-3">
                    There are a limited amount of categories available at this time to help you
                    match to propsective podcasts. More categories will become available
                    throughout the year.
                  </p>
                </template>
              </CategoryInput>

              <Divider />

              <BaseCheckbox
                v-model="stepOneForm.allowExplicit"
                fieldId="allowExplicitContent"
                fieldName="allowExplicit"
                fieldLabel="Include shows with explicit content"
                binary
                :trueValue="1"
                :falseValue="0"
              />

              <Divider />

              <CategoryInput
                fieldLabel="Sensitive Product/Service Being Advertised"
                fieldName="sensitiveCategories"
                :categoryOptions="sensitiveCategoryOptions"
                v-model="stepOneForm.sensitiveCategories"
                helperText="Choose any sensitive subjects that
                  are relevant to your product or service."
              />
            </form>
          </VeeForm>
        </template>
      </Card>

      <!-- Step 2 -->
      <Card v-else-if="activeStep === 2">
        <template #content>
          <WizardStepHeader
            :stepNumber="2"
            title="Budget and Ads info"
          />

          <Divider />

          <VeeForm
            v-slot="{ handleSubmit }"
            as="div"
            :validationSchema="stepTwoSchema"
            @invalidSubmit="onInvalidSubmit"
          >
            <form
              id="campaign-form"
              @submit.prevent="handleSubmit($event, onSubmitStepTwo)"
            >
              <div class="grid">
                <div class="col-12 sm:col-6">
                  <BaseInputNumber
                    v-model="stepTwoForm.budget"
                    fieldId="budget"
                    fieldName="budget"
                    fieldLabel="Budget"
                    placeholder="Enter your budget for this campaign"
                    mode="currency"
                    currency="USD"
                  />
                </div>

                <div class="col-12 sm:col-6">
                  <BaseSelectButton
                    v-model="stepTwoForm.budgetAllocation"
                    fieldId="budgetAllocation"
                    fieldName="budgetAllocation"
                    fieldLabel="Budget allocation"
                    :options="BUDGET_ALLOCATION_OPTIONS"
                    :allowEmpty="false"
                  />
                </div>

                <div class="col-12 sm:col-4">
                  <BaseDropdown
                    v-model="stepTwoForm.adRunSlot"
                    fieldId="adRunSlot"
                    fieldName="adRunSlot"
                    fieldLabel="Ad Run Slot"
                    :options="AD_RUN_SLOT_OPTIONS"
                    placeholder="Choose when the ad should run"
                  />
                </div>

                <div class="col-12 sm:col-4">
                  <BaseDropdown
                    v-model="stepTwoForm.adLength"
                    fieldId="adLength"
                    fieldName="adLength"
                    fieldLabel="Ad Length"
                    class="flex-grow-1"
                    placeholder="Choose an ad length"
                    :options="AD_LENGTH_OPTIONS"
                  />
                </div>
              </div>

              <div class="grid">
                <div class="col-12">
                  <Divider />
                </div>
              </div>

              <div class="grid">
                <div class="col-12 sm:col-8">
                  <BaseInputNumber
                    v-model="stepTwoForm.bidCap"
                    fieldId="bidCap"
                    fieldName="bidCap"
                    fieldLabel="Bid CPM"
                    mode="currency"
                    currency="USD"
                  >
                    <template #helperText>
                      <small id="bidCap-help">
                        <p>
                          Bid per 1,000 impressions
                        </p>
                        <p>
                          <strong>Note</strong>:
                          The higher the CPM, the more likely you will match additional podcasts.
                        </p>
                      </small>
                    </template>
                  </BaseInputNumber>

                  <BidWarning
                    class="mb-2"
                    :userType="USER_TYPE_ADVERTISER"
                    :adRunSlot="stepTwoForm.adRunSlot"
                    :adLength="stepTwoForm.adLength"
                    :cpm="stepTwoForm.bidCap"
                  />
                  <BidRecommendation
                    v-if="stepTwoForm.adRunSlot && stepTwoForm.adLength"
                    :userType="USER_TYPE_ADVERTISER"
                    :adRunSlot="stepTwoForm.adRunSlot"
                    :adLength="stepTwoForm.adLength"
                  />
                </div>
              </div>

              <div class="grid">
                <div class="col-12 sm:col-8">
                  <BaseInputNumber
                    v-model="stepTwoForm.frequencyCap"
                    fieldId="frequencyCap"
                    fieldName="frequencyCap"
                    fieldLabel="Frequency Cap"
                    helperText="Maximum number of times a user can
                      receive the ad within a single ad break."
                  />
                </div>
              </div>

              <div class="grid">
                <div class="col-12">
                  <Divider />
                </div>

                <div class="col-12 sm:col-6">
                  <BaseCalendar
                    v-model="stepTwoForm.dates"
                    fieldId="campaignDates"
                    fieldName="dates"
                    fieldLabel="Campaign Dates"
                    :numberOfMonths="2"
                    selectionMode="range"
                    :manualInput="false"
                    helperText="We recommend a minimum of a one month campaign."
                  />
                </div>
              </div>
            </form>
          </VeeForm>
        </template>
      </Card>

      <!-- Step 3 -->
      <Card v-else-if="activeStep === 3">
        <template #content>
          <WizardStepHeader
            :stepNumber="3"
            title="Design your ad"
          />

          <Divider />

          <VeeForm
            v-slot="{ handleSubmit }"
            as="div"
            :validationSchema="stepThreeSchema"
            @invalidSubmit="onInvalidSubmit"
          >
            <form
              id="campaign-form"
              @submit.prevent="handleSubmit($event, onSubmitStepThree)"
            >
              <div class="grid">
                <div class="col-12">
                  <BaseInput
                    v-model="stepThreeForm.adTitle"
                    fieldId="adTitle"
                    fieldName="adTitle"
                    fieldLabel="Title"
                    :characterLimit="AD_TITLE_CHARACTER_LIMIT"
                  />
                </div>

                <div class="col-12">
                  <Message
                    class="mt-0"
                    severity="secondary"
                    icon="pi pi-info-circle"
                    :closable="false"
                    :pt="{
                      icon: {
                        class: 'mt-1 text-xl align-self-start',
                      },
                      text: {
                        class: 'font-normal text-sm',
                      },
                    }"
                  >
                    Be advised that personal endorsements are not currently available.
                    Do not add any language suggesting a personal endorsement.
                  </Message>

                  <TabView v-model:activeIndex="adCopyTabIndex">
                    <TabPanel header="Generate AI Ad Copy">
                      <small>
                        Upload the "talking points" document for your ad.
                        This document describes the product or service being advertised.
                      </small>
                      <VeeField
                        name="adDocument"
                        v-model="stepThreeForm.adDocument"
                        v-slot="{ errors }"
                      >
                        <SingleFileUpload
                          v-model="stepThreeForm.adDocument"
                          :invalid="errors.length > 0"
                          :error="errors[0]"
                        />
                      </VeeField>

                      <template
                        v-if="documentAdUrl || isDocumentAdLoading"
                      >
                        <Divider />

                        <div class="font-bold">
                          Example Ad
                        </div>
                        <p class="text-sm">
                          This is an example ad based on your settings
                          and one of our demo voiceprint ekos.
                          <span class="inline-block mt-2">
                            <strong>Note:</strong> This example uses a demo
                              voiceprint eko. The ads generated for the campaign will be
                              personalized to the eko of each host.
                          </span>
                        </p>

                        <AudioCard
                          class="mt-3"
                          :url="documentAdUrl"
                          :isLoading="isDocumentAdLoading"
                        />

                        <div class="mt-3">
                          <small>
                            This is the ad script that matches the above audio.<br />
                            <strong>Note:</strong> Each ad script will be personalized
                            to the host based on their inputs to make it more authentic
                            and may vary from this script.
                          </small>
                          <Skeleton
                            v-if="isDocumentAdLoading"
                            class="mt-2"
                            height="74px"
                          />
                          <Textarea
                            v-else
                            class="w-full mt-2"
                            :value="documentAdScript"
                            disabled
                            :autoResize="true"
                          />

                          <div class="mt-2">
                            <small>
                              <strong>Note:</strong> If a word is mis-pronounced or you don't
                              like the script, delete the existing talking points,
                              modify the talking points to reflect the change you'd like
                              (e.g., change the spelling of "ekoz.ai" to echoes dot ai"
                              to get proper pronunciation), and then reload the revised
                              talking points document."
                            </small>
                          </div>
                        </div>
                      </template>
                    </TabPanel>
                    <TabPanel header="Read Script Verbatim">
                      <BaseTextarea
                        v-model="stepThreeForm.campaignScript"
                        fieldId="campaignScript"
                        fieldName="campaignScript"
                        helperText="Add your script that should be read verbatim."
                        @blur="onCampaignScriptBlur"
                      />

                      <Button
                        label="Generate Example Ad"
                        outlined
                        @click="onGenerateVerbatimAd"
                      />

                      <Divider />

                      <template v-if="verbatimAdIsLoading || verbatimAdUrl">
                        <div class="font-bold">
                          Example Ad
                        </div>
                        <p class="text-sm">
                          This is an example ad based on your settings
                          and one of our demo voiceprint ekos.
                          <span class="inline-block mt-2">
                            <strong>Note:</strong> This example uses a demo
                              voiceprint eko. The ads generated for the campaign will use
                              the eko of each host.
                          </span>
                        </p>

                        <AudioCard
                          class="mt-3"
                          :url="verbatimAdUrl"
                          :isLoading="verbatimAdIsLoading"
                        />

                        <div class="mt-2">
                          <small>
                            <strong>Note:</strong> If you don't like the example ad,
                            update your verbatim script and click
                            "Generate Example Ad" to generate a new ad.
                          </small>
                        </div>
                      </template>
                    </TabPanel>
                  </TabView>
                </div>

                <div class="col-12">
                  <BaseInput
                  v-model="stepThreeForm.trackingPixel"
                  fieldId="trackingPixel"
                  fieldName="trackingPixel"
                  fieldLabel="Tracking Pixel URL"
                  />
                </div>
              </div>
            </form>
          </VeeForm>
        </template>
      </Card>

      <CampaignSummaryCard
        :campaignName="stepOneForm.name"
        :startDate="stepTwoForm.dates && stepTwoForm.dates[0]
          ? stepTwoForm.dates[0]
          : null"
        :endDate="stepTwoForm.dates && stepTwoForm.dates[1]
          ? stepTwoForm.dates[1]
          : null"
        :budget="stepTwoForm.budget"
        :bidCpm="stepTwoForm.bidCap"
        :adRunSlot="stepTwoForm.adRunSlot"
        :adLength="stepTwoForm.adLength"
        :adType="adType"
        :estimatedImpressions="estimatedImpressions"
      />

      <ConfirmDialog
        v-model:visible="unsavedChangesDialogIsVisible"
        header="You have unsaved changes"
        @confirm="() => {
          this.formHasChanges = false;
          this.unsavedChangesDialogIsVisible = false;
          this.onToggle(false);
        }"
        confirmButtonLabel="Leave"
        cancelButtonLabel="Stay"
      >
        <p>
          There are unsaved changes to your campaign.
          Are you sure you want to leave?
        </p>
      </ConfirmDialog>
    </ScrollContainer>

    <template #footer>
      <div class="wizard-container flex flex-wrap justify-content-between row-gap-1">
        <Button
          text
          plain
          :label="cancelButtonLabel"
          :disabled="isSubmitting"
          @click="()=> onToggle(false)"
        />
        <div class="flex">
          <Button
            v-if="activeStep !== 1"
            text
            plain
            label="Previous"
            :disabled="isSubmitting"
            @click="onClickPrevious"
          />
          <Button
            class="ml-2"
            :label="nextButtonText"
            type="submit"
            :disabled="isDocumentAdLoading || verbatimAdIsLoading"
            form="campaign-form"
            :loading="isSubmitting"
          />
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import {
  array,
  date,
  mixed,
  number,
  object,
  string,
} from 'yup';
import { DateTime } from 'luxon';
import GenderInput from '@/components/genderInput';
import TargetAgeInput from '@/components/targetAgeInput';
import GeographicTargetMessage from '@/components/geographicTargetMessage';
import {
  AGE_GROUP_OPTIONS,
  AD_RUN_SLOT_OPTIONS,
  BUDGET_ALLOCATION_OPTIONS,
  BUDGET_ALLOCATION_UNIFORM_BUDGET,
  AD_LENGTH_OPTIONS,
  AD_LENGTH_30_SECONDS,
  GENDER_ALL,
  USER_TYPE_ADVERTISER,
} from '@/constants';
import * as api from '@/api';
import { INVALID_FORM_SUBMISSION_MESSAGE } from '@/utils/messages';
import { isJsonString } from '@/utils/helpers';
import { parseMessageFromError } from '@/utils/errors';
import CategoryInput from '@/components/categoryInput';
import { calculateImpressions } from '@/utils/impressions';
import { ExistingDocument } from '@/utils/document';
import WizardStepHeader from './wizardStepHeader';
import SingleFileUpload from './singleFileUpload';
import CampaignSummaryCard from './campaignSummaryCard';
import BidRecommendation from './bidRecommendation';
import BidWarning from './bidWarning';
import ConfirmDialog from './confirmDialog';
import AudioCard from './audioCard';
import ScrollContainer from './scrollContainer';

const AD_TITLE_CHARACTER_LIMIT = 255;
const AD_COPY_DOCUMENT_INDEX = 0;
const AD_COPY_SCRIPT_INDEX = 1;

const generateEmptyStepOneForm = () => {
  const preselectedCategoryOptions = typeof process.env.VUE_APP_PRESELECTED_CAMPAIGN_CATEGORIES === 'string'
    && process.env.VUE_APP_PRESELECTED_CAMPAIGN_CATEGORIES !== ''
    ? process.env.VUE_APP_PRESELECTED_CAMPAIGN_CATEGORIES.split(',').map((id) => Number(id))
    : [];

  return {
    name: '',
    targetGender: GENDER_ALL,
    targetAgeRanges: [...AGE_GROUP_OPTIONS],
    allowExplicit: 0,
    categories: preselectedCategoryOptions,
    sensitiveCategories: [],
  };
};
const generateEmptyStepTwoForm = () => ({
  budget: null,
  adRunSlot: null,
  dates: [],
  budgetAllocation: BUDGET_ALLOCATION_UNIFORM_BUDGET,
  bidCap: null,
  frequencyCap: 1,
  adLength: AD_LENGTH_30_SECONDS,
});
const generateEmptyStepThreeForm = () => ({
  adTitle: '',
  campaignScript: '',
  adDocument: null,
  trackingPixel: '',
  exampleGeneratedAdId: null,
});

export default {
  components: {
    WizardStepHeader,
    TargetAgeInput,
    SingleFileUpload,
    GenderInput,
    CategoryInput,
    CampaignSummaryCard,
    BidRecommendation,
    BidWarning,
    ConfirmDialog,
    AudioCard,
    ScrollContainer,
    GeographicTargetMessage,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    campaign: {
      validator: (prop) => typeof prop === 'object' || prop === null,
    },
    categoryOptions: {
      type: Array,
      default: () => ([]),
    },
    sensitiveCategoryOptions: {
      type: Array,
      default: () => ([]),
    },
    cancelButtonLabel: {
      type: String,
      default: 'Cancel',
    },
  },
  computed: {
    nextButtonText() {
      switch (this.activeStep) {
        case 3:
          return 'Save and Approve';
        default:
          return 'Next';
      }
    },
    documentAdParams() {
      return {
        adLength: this.stepTwoForm.adLength,
        adDocument: this.stepThreeForm.adDocument,
      };
    },
    estimatedImpressionParams() {
      return {
        targetGender: this.stepOneForm.targetGender,
        targetAgeRanges: this.stepOneForm.targetAgeRanges,
        categories: this.stepOneForm.categories,
        budget: this.stepTwoForm.budget,
        bidCap: this.stepTwoForm.bidCap,
      };
    },
    adType() {
      switch (this.adCopyTabIndex) {
        case AD_COPY_DOCUMENT_INDEX:
          return 'AI Generated Script';
        case AD_COPY_SCRIPT_INDEX:
          return 'Verbatim Script';
        default:
          return 'N/A';
      }
    },
    formattedCategoryOptions() {
      const preselectedCategoryOptions = typeof process.env.VUE_APP_PRESELECTED_CAMPAIGN_CATEGORIES === 'string'
        && process.env.VUE_APP_PRESELECTED_CAMPAIGN_CATEGORIES !== ''
        ? process.env.VUE_APP_PRESELECTED_CAMPAIGN_CATEGORIES.split(',').map((id) => Number(id))
        : [];

      return this.categoryOptions.map((item) => ({
        ...item,
        disabled: preselectedCategoryOptions.length > 0
          && !preselectedCategoryOptions.includes(item.value),
      }));
    },
  },
  watch: {
    visible: {
      immediate: true,
      handler(newVal) {
        this.activeStep = 1;

        if (newVal === true && this.campaign) {
          try {
            const targetAgeRanges = this.campaign.target_ages
              && isJsonString(this.campaign.target_ages)
              ? Object.keys(JSON.parse(this.campaign.target_ages))
              : [...AGE_GROUP_OPTIONS];

            const categories = this.campaign.target_categories
              && isJsonString(this.campaign.target_categories)
              ? JSON.parse(this.campaign.target_categories)
              : [];

            const sensitiveCategories = this.campaign.sensitive_categories;

            this.stepOneForm = {
              name: this.campaign.campaign_name || '',
              targetGender: this.campaign.target_gender || GENDER_ALL,
              targetAgeRanges,
              allowExplicit: !Number.isNaN(Number(this.campaign.allow_explicit))
                ? Number(this.campaign.allow_explicit)
                : 0,
              categories,
              sensitiveCategories,
            };
            this.stepTwoForm = {
              budget: !Number.isNaN(Number(this.campaign.max_budget))
                ? Number(this.campaign.max_budget)
                : null,
              adRunSlot: this.campaign.ad_run_slot || null,
              dates: [
                DateTime.fromISO(this.campaign.start_date).toJSDate(),
                DateTime.fromISO(this.campaign.end_date).toJSDate(),
              ],
              budgetAllocation: this.campaign.budget_allocation || BUDGET_ALLOCATION_UNIFORM_BUDGET,
              bidCap: !Number.isNaN(Number(this.campaign.bid_cap))
                ? Number(this.campaign.bid_cap)
                : null,
              frequencyCap: !Number.isNaN(Number(this.campaign.frequency_cap))
                ? Number(this.campaign.frequency_cap)
                : 1,
              adLength: !Number.isNaN(Number(this.campaign.ad_length))
                ? Number(this.campaign.ad_length)
                : AD_LENGTH_30_SECONDS,
            };

            const adDocument = this.campaign.uploaded_talking_points_document_s3_url
              ? new ExistingDocument('Talking Points Document', this.campaign.uploaded_talking_points_document_s3_url)
              : null;

            this.stepThreeForm = {
              adTitle: this.campaign.ad_title || '',
              campaignScript: this.campaign.ad_script || '',
              adDocument,
              trackingPixel: this.campaign.ext_track_pixel || '',
              exampleGeneratedAdId: this.campaign.example_generated_ad_id || null,
            };

            this.adCopyTabIndex = this.campaign.ad_script
              ? AD_COPY_SCRIPT_INDEX
              : AD_COPY_DOCUMENT_INDEX;
          } catch (error) {
            const message = parseMessageFromError(error, 'Error loading campaign data into edit form.');

            this.$toast.add({
              severity: 'error',
              detail: message,
            });
          }
        } else if (newVal === true && !this.campaign) {
          this.stepOneForm = generateEmptyStepOneForm();
          this.stepTwoForm = generateEmptyStepTwoForm();
          this.stepThreeForm = generateEmptyStepThreeForm();

          this.adCopyTabIndex = AD_COPY_DOCUMENT_INDEX;
        }

        // set up form changes watchers
        if (newVal === true) {
          this.formHasChanges = false;

          if (this.stepOneUnwatch) this.stepOneUnwatch();
          this.stepOneUnwatch = this.$watch('stepOneForm', function stepOneWatch() {
            this.formHasChanges = true;
          }, {
            deep: true,
          });

          if (this.stepTwoUnwatch) this.stepTwoUnwatch();
          this.stepTwoUnwatch = this.$watch('stepTwoForm', function stepTwoWatch() {
            this.formHasChanges = true;
          }, {
            deep: true,
          });

          if (this.stepThreeUnwatch) this.stepThreeUnwatch();
          this.stepThreeUnwatch = this.$watch('stepThreeForm', function stepThreeWatch() {
            this.formHasChanges = true;
          }, {
            deep: true,
          });
        }

        if (newVal === true) {
          window.addEventListener('beforeunload', this.beforeUnload);
        } else {
          window.removeEventListener('beforeunload', this.beforeUnload);
        }

        // clear demo ad data
        if (newVal === true) {
          this.documentAdUrl = '';
          this.documentAdScript = '';
          this.verbatimAdUrl = '';
        }
      },
    },
    activeStep() {
      if (this.$refs.dialogBody && this.$refs.dialogBody.$el) {
        this.$refs.dialogBody.$el.scrollIntoView({
          behavior: 'smooth',
        });
      }
    },
    documentAdParams: {
      immediate: true,
      async handler() {
        await this.generateDocumentAd();
      },
    },
    estimatedImpressionParams: {
      immediate: true,
      async handler() {
        await this.getEstimatedImpressions();
      },
    },
  },
  data() {
    return {
      // unsaved changes feature
      formHasChanges: false,
      stepOneUnwatch: null,
      stepTwoUnwatch: null,
      stepThreeUnwatch: null,
      unsavedChangesDialogIsVisible: false,

      AD_RUN_SLOT_OPTIONS,
      BUDGET_ALLOCATION_OPTIONS,
      AD_LENGTH_OPTIONS,
      AD_TITLE_CHARACTER_LIMIT,
      USER_TYPE_ADVERTISER,
      activeStep: 1,
      stepOneSchema: object({
        name: string().required('Name is required'),
      }),
      stepOneForm: generateEmptyStepOneForm(),
      stepTwoSchema: object({
        budget: number().required('Budget is required'),
        adRunSlot: string().required('Ad run slot is required'),
        bidCap: number().required('Bid CPM is required'),
        frequencyCap: number().required('Frequency cap is required')
          .min(1, 'Frequency cap must be greater than 0'),
        dates: array()
          .length(2, 'Must have start & end dates')
          .required('Campaign dates are required')
          .of(date().required('Must have start & end dates')),
      }),
      stepTwoForm: generateEmptyStepTwoForm(),
      stepThreeSchema: object({
        adTitle: string().required('Ad title is required').max(AD_TITLE_CHARACTER_LIMIT, `Ad title can be a max of ${AD_TITLE_CHARACTER_LIMIT} characters.`),
        campaignScript: string().when([], {
          is: () => this.adCopyTabIndex === AD_COPY_SCRIPT_INDEX,
          then: (schema) => schema.required('Ad script is required'),
          otherwise: (schema) => schema,
        }),
        adDocument: mixed().when([], {
          is: () => this.adCopyTabIndex === AD_COPY_DOCUMENT_INDEX,
          then: (schema) => schema.required('Ad Document is required'),
          otherwise: (schema) => schema.nullable(),
        }),
        trackingPixel: string().url('Tracking pixel must be a valid URL'),
      }),
      stepThreeForm: generateEmptyStepThreeForm(),
      adCopyTabIndex: AD_COPY_DOCUMENT_INDEX,
      // demo document ad
      isDocumentAdLoading: false,
      documentAdUrl: '',
      documentAdScript: '',
      // demo verbatim ad
      verbatimAdIsLoading: false,
      verbatimAdUrl: '',
      // campaign summary
      estimatedImpressions: 0,
    };
  },
  methods: {
    onToggle(visible) {
      if (this.formHasChanges) {
        this.unsavedChangesDialogIsVisible = true;
        return;
      }

      this.$emit('update:visible', visible);
    },
    onInvalidSubmit() {
      this.$toast.add({
        severity: 'warn',
        detail: INVALID_FORM_SUBMISSION_MESSAGE,
      });
    },
    beforeUnload(event) {
      if (this.formHasChanges) {
        event.preventDefault();
        // eslint-disable-next-line no-alert
        alert('There are unsaved changes to your campaign. Are you sure you want to leave?');
      }
    },
    onSubmitStepOne() {
      this.activeStep = 2;
    },
    onSubmitStepTwo() {
      this.activeStep = 3;
    },
    async onSubmitStepThree() {
      let campaignScript = null;
      let adDocument = null;

      switch (this.adCopyTabIndex) {
        case AD_COPY_DOCUMENT_INDEX:
          // clear script if document is used
          campaignScript = null;
          adDocument = this.stepThreeForm.adDocument;
          break;
        case AD_COPY_SCRIPT_INDEX:
          // if user did not manually generate verbatim example ad
          // generate for them, so it gets saved to campaign
          if (!this.verbatimAdUrl) {
            await this.onGenerateVerbatimAd();
          }

          // clear document is script is used
          campaignScript = this.stepThreeForm.campaignScript;
          adDocument = null;
          break;
        default:
            // do nothing
      }

      this.$emit('submit', {
        campaign: this.campaign,
        campaignForm: {
          ...this.stepOneForm,
          ...this.stepTwoForm,
          ...this.stepThreeForm,
          campaignScript,
          adDocument,
        },
      });
    },
    onClickPrevious() {
      switch (this.activeStep) {
        case 1:
          this.onToggle(false);
          break;
        case 2:
          this.activeStep = 1;
          break;
        case 3:
          this.activeStep = 2;
          break;
        default:
          // do nothing
      }
    },
    async generateDocumentAd() {
      if (!this.documentAdParams.adLength
        || !this.documentAdParams.adDocument
        || (this.documentAdParams.adDocument
          && this.documentAdParams.adDocument instanceof ExistingDocument)
      ) {
        return;
      }

      try {
        this.isDocumentAdLoading = true;

        const scriptMinutes = Math.floor(this.documentAdParams.adLength / 60);
        const scriptSeconds = this.documentAdParams.adLength % 60;

        const res = await api.createGeneratedAd({
          demoVoice: true,
          adFile: this.documentAdParams.adDocument,
          scriptMinutes,
          scriptSeconds,
        });

        this.documentAdUrl = res.s3_url;
        this.documentAdScript = res.script;
        this.stepThreeForm.exampleGeneratedAdId = res.generated_ad_id;
      } catch (error) {
        const message = parseMessageFromError(error, 'Error generating demo ad');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.isDocumentAdLoading = false;
      }
    },
    async onGenerateVerbatimAd() {
      try {
        this.verbatimAdIsLoading = true;

        const res = await api.createTextToSpeech({
          demoVoice: true,
          text: this.stepThreeForm.campaignScript,
        });

        this.verbatimAdUrl = res.s3_url;
        this.stepThreeForm.exampleGeneratedAdId = res.generated_ad_id;
      } catch (error) {
        const message = parseMessageFromError(error, 'Error generating ad verbatim.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.verbatimAdIsLoading = false;
      }
    },
    async getEstimatedImpressions() {
      try {
        if (
          !this.estimatedImpressionParams.bidCap
          || !this.estimatedImpressionParams.budget
        ) {
          return;
        }

        const impressionGoal = calculateImpressions(
          this.estimatedImpressionParams.budget,
          this.estimatedImpressionParams.bidCap,
        );

        const targetAges = this.estimatedImpressionParams.targetAgeRanges.reduce((acc, item) => {
          acc[item] = true;

          return acc;
        }, {});

        const res = await api.calculateTargetImpressions({
          targetGender: this.estimatedImpressionParams.targetGender,
          targetAges,
          targetCategories: this.estimatedImpressionParams.categories,
          impressionGoal,
          bidCap: this.estimatedImpressionParams.bidCap,
          maxBudget: this.estimatedImpressionParams.budget,
        });

        this.estimatedImpressions = res.data.available_impressions;
      } catch (error) {
        const message = parseMessageFromError(error, 'Error estimating impressions');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
    onCampaignScriptBlur() {
      // reset verbatim ad example.
      // this is needed for the case where the user
      //  1. generates a verbatim ad
      //  2. updates their verbatim script
      //  3. approves/saves ad before generating a new verbatim example
      // The wrong example would be saved to the campaign. This ensures
      // a new example must be created before saving.
      this.verbatimAdUrl = null;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/variables";

.campaign-form-dialog {
  padding-top: 58px;

  @media screen and (
    min-width: #{map-get($breakpoints, 'md')}
  ) {
    padding-top: 0;
    padding-right: 260px;
  }
  @media screen and (
    min-width: #{map-get($breakpoints, 'lg')}
  ) {
    padding-right: 300px;
  }
}
</style>

<style lang="scss" scoped>
@import "@/styles/variables";
</style>
