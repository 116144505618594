import { defineStore } from 'pinia';
import * as api from '@/api';

export const useUsersStore = defineStore('users', {
  state: () => ({
    users: [],
    usersAreLoading: false,
  }),
  getters: {
    getUsersByOrganizationId: (state) => (
      (organizationId) => state.users.filter((item) => item.organization_id === organizationId)
    ),
    getUserOptionsByOrganizationId() {
      return (organizationId) => this.getUsersByOrganizationId(organizationId).map((user) => ({
        value: Number(user.id),
        label: `${user.fname} ${user.lname}`.trim(),
        user,
      }));
    },
  },
  actions: {
    async getUsers({
      organizationId,
    } = {}) {
      try {
        this.usersAreLoading = true;

        const res = await api.readUsers({
          organizationId,
        });
        this.users = res;
      } finally {
        this.usersAreLoading = false;
      }
    },
  },
});
