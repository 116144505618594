<template>
  <Dialog
    :visible="visible"
    :dismissableMask="!isSubmitting"
    modal
    @update:visible="onClose"
    :pt="{
      closeButton: {
        disabled: isSubmitting,
      },
      content: {
        style: {
          maxWidth: '600px',
        },
      },
    }"
    header="Replace Ad"
  >
    <p>
      You may record & upload your own version of the ad.
    </p>

    <template v-if="adScript">
      <WarningMessage>
        <strong>Note:</strong> You must read the provided ad script verbatim.
      </WarningMessage>

      <BaseTextarea
        class="w-full mt-2"
        :modelValue="adScript"
        fieldId="adScript"
        fieldName="adScript"
        fieldLabel="Ad Script"
        disabled
        :autoResize="true"
      />

      <div class="flex justify-content-end mb-2">
        <Button
          severity="info"
          icon="pi pi-clipboard"
          label="Copy Ad Script"
          @click="onCopyAdScript"
        />
      </div>

      <Divider />
    </template>

    <VeeForm
      v-slot="{ handleSubmit }"
      as="div"
      :validationSchema="schema"
      @invalidSubmit="onInvalidSubmit"
    >
      <form
        id="upload-ad-form"
        @submit.prevent="handleSubmit($event, onSubmit)"
      >
        <BaseFileUpload
          v-model="form.adFiles"
          fieldId="adFiles"
          fieldName="adFiles"
          accept="audio/mpeg"
          dragText="Drag your mp3 file here"
        />
      </form>
    </VeeForm>

    <template #footer>
      <div class="flex justify-content-end">
        <Button
          text
          plain
          label="Cancel"
          :disabled="isSubmitting"
          @click="$emit('update:visible', false)"
        />
        <Button
          class="ml-2"
          type="submit"
          form="upload-ad-form"
          label="Submit"
          :loading="isSubmitting"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { array } from 'yup';
import { INVALID_FORM_SUBMISSION_MESSAGE } from '@/utils/messages';
import { copyTextToClipboard } from '@/utils/helpers';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    adScript: {
      validator: (prop) => typeof prop === 'string' || prop === null,
    },
  },
  watch: {
    visible: {
      immediate: true,
      handler(newVal) {
        if (newVal === true) {
          this.form.adFiles = [];
        }
      },
    },
  },
  data() {
    return {
      schema: {
        adFiles: array().min(1, 'Must upload at least 1 file').required(),
      },
      form: {
        adFiles: [],
      },
    };
  },
  methods: {
    onClose(event) {
      if (this.isSubmitting) return;

      this.$emit('update:visible', event);
    },
    onInvalidSubmit() {
      this.$toast.add({
        severity: 'warn',
        detail: INVALID_FORM_SUBMISSION_MESSAGE,
      });
    },
    onSubmit() {
      this.$emit('submit', this.form.adFiles[0]);
    },
    async onCopyAdScript() {
      try {
        await copyTextToClipboard(this.adScript);

        this.$toast.add({
          severity: 'success',
          detail: 'Ad script copied to clipboard',
        });
      } catch (error) {
        this.$toast.add({
          severity: 'error',
          detail: 'Error copying ad script to clipboard',
        });
      }
    },
  },
};
</script>
