<template>
  <WarningMessage
    v-if="showWarning"
  >
    {{ warningMessage }}
  </WarningMessage>
</template>

<script>
import { USER_TYPE_ADVERTISER, USER_TYPE_HOST } from '@/constants';
import { getBidRecommendation } from '@/utils/bidRecommendations';

export default {
  props: {
    userType: String,
    adRunSlot: String,
    adLength: Number,
    cpm: {
      validator: (prop) => typeof prop === 'number' || prop === null,
    },
  },
  computed: {
    showWarning() {
      if (this.cpm === null
        || this.bidRecommendation.min === null
        || this.bidRecommendation.max === null
      ) {
        return false;
      }

      switch (this.userType) {
        case USER_TYPE_ADVERTISER:
          return this.cpm < this.bidRecommendation.min;
        case USER_TYPE_HOST:
          return this.cpm > this.bidRecommendation.max;
        default:
          return false;
      }
    },
    warningMessage() {
      switch (this.userType) {
        case USER_TYPE_ADVERTISER:
          return `Your bid CPM is below our recommendation.
            This may limit the amount of inventory we can provide to your advertisement.`;
        case USER_TYPE_HOST:
          return `Your CPM exceeds our recommendation.
            This may limit the amount of advertisers we can match
            to your podcast.`;
        default:
          return 'Your CPM is not within the range of our recommendation.';
      }
    },
    bidRecommendation() {
      return getBidRecommendation(
        this.userType,
        this.adRunSlot,
        this.adLength,
      );
    },
  },
};
</script>
