<template>
  <Message
    class="m-0"
    severity="warn"
    icon="pi pi-exclamation-triangle"
    :closable="false"
    :pt="{
      icon: {
        class: 'mt-1 text-xl align-self-start',
      },
      text: {
        class: 'font-normal text-sm',
      },
    }"
  >
    <slot />
  </Message>
</template>

<script>
export default {
  props: {
    message: String,
  },
};
</script>
