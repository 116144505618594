<template>
  <Dialog
    :visible="visible"
    :dismissableMask="!isSubmitting"
    modal
    header="Export signup data"
    @update:visible="onClose"
    :pt="{
      closeButton: {
        disabled: isSubmitting,
      },
      content: {
        style: {
          maxWidth: '400px',
        },
      },
    }"
  >
    <div>
      Click "Export" to generate a csv of the latest signup data.
    </div>
    <template #footer>
      <div class="flex justify-content-end">
        <Button
          text
          plain
          label="Close"
          :disabled="isSubmitting"
          @click="$emit('update:visible', false)"
        />
        <Button
          class="ml-2"
          label="Export"
          :disabled="isSubmitting"
          :loading="isSubmitting"
          @click="onExportData"
        >
          Export
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import {
  readOrganizations,
  readPrograms,
  readRoles,
  readUsers,
} from '@/api';
import { parseMessageFromError } from '@/utils/errors';
import { exportToCsv } from '@/utils/helpers';
import { DateTime } from 'luxon';
import _orderBy from 'lodash/orderBy';

const USER_ID = 'User id';
const USER_EMAIL = 'User email';
const USER_FIRST_NAME = 'User first name';
const USER_LAST_NAME = 'User last name';
const USER_TYPE = 'User type';
const USER_HAS_SETUP_VOICE = 'User has setup voice (host only)';
const USER_SIGNED_UP_AT = 'User signed up at';
const ORGANIZATION_ID = 'Organization id';
const ORGANIZATION_NAME = 'Organization name';
const ORGANIZATION_HAS_ADDED_CC = 'Organization has added credit card (advertiser only)';
const PROGRAM_COUNT = 'Program count';
const PROGRAM_NAMES = 'Program names';
const PROGRAM_RSS_URLS = 'Program rss urls';
const PROGRAM_VERIFIED = 'Program is verified';
const PROGRAM_LISTENER_TOTAL = 'Program listener total (sum across all programs)';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSubmitting: false,
    };
  },
  methods: {
    onClose(event) {
      if (this.isSubmitting) return;

      this.$emit('update:visible', event);
    },
    async onExportData() {
      try {
        this.isSubmitting = true;

        const users = await readUsers({});
        const organizations = await readOrganizations();
        const programs = await readPrograms({});
        const { roles } = await readRoles();

        const compiledData = _orderBy(users, 'created_at', 'desc').map((user) => {
          const matchingOrganization = organizations
            .find((organization) => Number(user.organization_id) === Number(organization.id));
          const matchingPrograms = user.organization_id
            ? programs
              .filter((program) => Number(program.organization_id) === Number(user.organization_id))
            : [];
          const matchingRole = roles.find((role) => Number(role.id) === Number(user.role_id));

          let hasSetupVoice = '';

          if (matchingRole && matchingRole.user_type === 'host') {
            hasSetupVoice = user.voice_id
              ? 'Yes'
              : 'No';
          }

          let hasAddedCreditCard = '';

          if (matchingRole && matchingRole.user_type === 'advertiser') {
            hasAddedCreditCard = matchingOrganization
            && matchingOrganization.stripe_payment_method_id
              ? 'Yes'
              : 'No';
          }

          return {
            [USER_ID]: user.id,
            [USER_EMAIL]: user.email,
            [USER_FIRST_NAME]: user.fname,
            [USER_LAST_NAME]: user.lname,
            [USER_TYPE]: matchingRole ? matchingRole.user_type : '',
            [USER_HAS_SETUP_VOICE]: hasSetupVoice,
            [USER_SIGNED_UP_AT]: `"${DateTime.fromSQL(user.created_at)
              .toLocaleString(DateTime.DATETIME_FULL)}"`,
            [ORGANIZATION_ID]: user.organization_id,
            [ORGANIZATION_NAME]: matchingOrganization ? matchingOrganization.name : '',
            [ORGANIZATION_HAS_ADDED_CC]: hasAddedCreditCard,
            [PROGRAM_COUNT]: matchingPrograms.length,
            [PROGRAM_NAMES]: `"${matchingPrograms.map((program) => program.name).join(', ')}"`,
            [PROGRAM_RSS_URLS]: `"${matchingPrograms.map((program) => program.rss_url).join(', ')}"`,
            [PROGRAM_VERIFIED]: `"${matchingPrograms.map((program) => (program.email_confirmation_date ? 'Yes' : 'No')).join(', ')}"`,
            [PROGRAM_LISTENER_TOTAL]: matchingPrograms
              .reduce((acc, item) => acc + item.avg_listeners, 0),
          };
        });

        const filename = `signup_export_${DateTime.now().toLocaleString(DateTime.DATETIME_FULL)}`;

        exportToCsv(
          filename,
          [
            USER_ID,
            USER_EMAIL,
            USER_FIRST_NAME,
            USER_LAST_NAME,
            USER_TYPE,
            USER_HAS_SETUP_VOICE,
            USER_SIGNED_UP_AT,
            ORGANIZATION_ID,
            ORGANIZATION_NAME,
            ORGANIZATION_HAS_ADDED_CC,
            PROGRAM_COUNT,
            PROGRAM_NAMES,
            PROGRAM_RSS_URLS,
            PROGRAM_VERIFIED,
            PROGRAM_LISTENER_TOTAL,
          ],
          compiledData,
        );
      } catch (error) {
        const message = parseMessageFromError(error, 'Error loading campaign data into edit form.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>
