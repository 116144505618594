<template>
  <Menu
    :id="id"
    class="right-align-menu"
    ref="menu"
    popup
    :model="menuOptions"
  />
  <BetaSignupDialog
    v-model:visible="isSignupVisible"
  />
</template>

<script>
import { mapStores } from 'pinia';
import { useAuthStore, useMyUserStore } from '@/stores';
import { ROUTE_ADVERTISER_PROFILE, ROUTE_LOGIN } from '@/router/routes';
import BetaSignupDialog from '@/components/betaSignupDialog';

export default {
  components: {
    BetaSignupDialog,
  },
  props: {
    id: {
      type: String,
      default: 'profile-menu',
    },
  },
  data() {
    return {
      isSignupVisible: false,
      menuOptions: [
        {
          label: 'Profile',
          command: () => this.$router.push({ name: ROUTE_ADVERTISER_PROFILE }),
        },
        {
          label: 'Signup Data',
          command: () => {
            this.isSignupVisible = true;
          },
          visible: () => {
            const allowedUserIds = typeof process.env.VUE_APP_SIGNUP_EXPORT_ALLOWED_USER_IDS === 'string'
              && process.env.VUE_APP_SIGNUP_EXPORT_ALLOWED_USER_IDS !== ''
              ? process.env.VUE_APP_SIGNUP_EXPORT_ALLOWED_USER_IDS.split(',').map((id) => Number(id))
              : [];

            return !!allowedUserIds.find((userId) => userId === Number(this.myUserStore.userId));
          },
        },
        {
          label: 'Logout',
          command: () => this.logout(),
        },
      ],
    };
  },
  computed: {
    ...mapStores(useAuthStore, useMyUserStore),
  },
  methods: {
    toggle(event) {
      if (this.$refs.menu) {
        this.$refs.menu.toggle(event);
      }
    },
    logout() {
      this.authStore.logout();
      this.$router.push({
        name: ROUTE_LOGIN,
      });
    },
  },
};
</script>
