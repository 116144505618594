import ekozAxios from './instance/axios';

export const createOrganization = ({
  name,
}) => ekozAxios.request({
  method: 'post',
  url: '/organizations/new_organization.php',
  data: {
    name,
  },
});

export const updateOrganization = ({
  organizationId,
  name,
}) => ekozAxios.request({
  method: 'post',
  url: '/organizations/edit_organization.php',
  data: {
    id: organizationId,
    name,
  },
});

export const readOrganizations = (organizationId) => ekozAxios.request({
  method: 'get',
  url: '/organizations/get_organizations.php',
  params: {
    id: organizationId,
  },
});
