import { createApp } from 'vue';
import { createPinia } from 'pinia';

// primevue imports
import 'primeflex/primeflex.css';
import PrimeVue from 'primevue/config';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Image from 'primevue/image';
import MenuBar from 'primevue/menubar';
import Avatar from 'primevue/avatar';
import Menu from 'primevue/menu';
import OverlayPanel from 'primevue/overlaypanel';
import Message from 'primevue/message';
import BadgeDirective from 'primevue/badgedirective';
import Dialog from 'primevue/dialog';
import Stepper from 'primevue/stepper';
import StepperPanel from 'primevue/stepperpanel';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import Textarea from 'primevue/textarea';
import Slider from 'primevue/slider';
import RadioButton from 'primevue/radiobutton';
import Card from 'primevue/card';
import Badge from 'primevue/badge';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Chip from 'primevue/chip';
import Divider from 'primevue/divider';
import FileUpload from 'primevue/fileupload';
import Skeleton from 'primevue/skeleton';
import ButtonGroup from 'primevue/buttongroup';
import Tooltip from 'primevue/tooltip';
import InputGroup from 'primevue/inputgroup';
import MultiSelect from 'primevue/multiselect';
import MeterGroup from 'primevue/metergroup';
import DataView from 'primevue/dataview';
import Chart from 'primevue/chart';
import SelectButton from 'primevue/selectbutton';
import Calendar from 'primevue/calendar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Sidebar from 'primevue/sidebar';
import Panel from 'primevue/panel';
import InputGroupAddon from 'primevue/inputgroupaddon';
import '@/assets/theme/theme.css';
import 'primeicons/primeicons.css';

import { Field, Form } from 'vee-validate';

import DialogService from 'primevue/dialogservice';
import ToastService from '@/plugins/ToastService';
import PageTitle from '@/components/pageTitle';
import { formatBytes } from '@/utils/helpers';
import axios from '@/api/instance/axios';
import { useAuthStore } from '@/stores';

import PageContainer from './components/pageContainer';
import BaseInput from './components/form/baseInput';
import BasePassword from './components/form/basePassword';
import BaseTextarea from './components/form/baseTextarea';
import BaseDropdown from './components/form/baseDropdown';
import BaseCalendar from './components/form/baseCalendar';
import BaseInputNumber from './components/form/baseInputNumber';
import BaseMultiSelect from './components/form/baseMultiSelect';
import BaseSelectButton from './components/form/baseSelectButton';
import BaseFieldContainer from './components/form/baseFieldContainer';
import BaseCheckbox from './components/form/baseCheckbox';
import BaseFileUpload from './components/form/baseFileUpload';
import WarningMessage from './components/WarningMessage';
import { boostrapAxiosInterceptor } from './api/instance/interceptor';

import App from './App';
import router from './router';

import './styles/index.scss';

const pinia = createPinia();

const myApp = createApp(App)
  .use(PrimeVue)
  .use(pinia)
  .use(router)
  .use(ToastService)
  .use(DialogService);

// boostrap axios interceptor. must occur after .use(pinia)
const authStore = useAuthStore();
boostrapAxiosInterceptor(axios, router, authStore);

myApp.config.errorHandler = (error, vm, info) => {
  /* eslint-disable-next-line no-console */
  console.error('Unhandled error', error, vm, info);
};

myApp.config.globalProperties.$filters = {
  formatBytes,
};

// custom components
myApp.component('PageTitle', PageTitle);
myApp.component('PageContainer', PageContainer);
myApp.component('BaseFieldContainer', BaseFieldContainer);
myApp.component('BaseInput', BaseInput);
myApp.component('BasePassword', BasePassword);
myApp.component('BaseTextarea', BaseTextarea);
myApp.component('BaseDropdown', BaseDropdown);
myApp.component('BaseCalendar', BaseCalendar);
myApp.component('BaseInputNumber', BaseInputNumber);
myApp.component('BaseMultiSelect', BaseMultiSelect);
myApp.component('BaseSelectButton', BaseSelectButton);
myApp.component('BaseCheckbox', BaseCheckbox);
myApp.component('BaseFileUpload', BaseFileUpload);
myApp.component('WarningMessage', WarningMessage);

// vee-validate components
myApp.component('VeeField', Field);
myApp.component('VeeForm', Form);

// primevue components
myApp.component('Button', Button);
myApp.component('Checkbox', Checkbox);
myApp.component('InputText', InputText);
myApp.component('Password', Password);
myApp.component('Image', Image);
myApp.component('MenuBar', MenuBar);
myApp.component('Avatar', Avatar);
myApp.component('Menu', Menu);
myApp.component('OverlayPanel', OverlayPanel);
myApp.component('Message', Message);
myApp.component('Dialog', Dialog);
myApp.component('Stepper', Stepper);
myApp.component('StepperPanel', StepperPanel);
myApp.component('Dropdown', Dropdown);
myApp.component('InputNumber', InputNumber);
myApp.component('Textarea', Textarea);
myApp.component('Slider', Slider);
myApp.component('RadioButton', RadioButton);
myApp.component('Card', Card);
myApp.component('Badge', Badge);
myApp.component('DataTable', DataTable);
myApp.component('Column', Column);
myApp.component('Chip', Chip);
myApp.component('Divider', Divider);
myApp.component('FileUpload', FileUpload);
myApp.component('Skeleton', Skeleton);
myApp.component('ButtonGroup', ButtonGroup);
myApp.component('InputGroup', InputGroup);
myApp.component('MultiSelect', MultiSelect);
myApp.component('MeterGroup', MeterGroup);
myApp.component('DataView', DataView);
myApp.component('Chart', Chart);
myApp.component('SelectButton', SelectButton);
myApp.component('Calendar', Calendar);
myApp.component('TabView', TabView);
myApp.component('TabPanel', TabPanel);
myApp.component('Sidebar', Sidebar);
myApp.component('Panel', Panel);
myApp.component('InputGroupAddon', InputGroupAddon);

myApp.directive('badge', BadgeDirective);
myApp.directive('tooltip', Tooltip);

myApp.mount('#app');

/* eslint-disable-next-line no-console */
console.log(`Vue app: ${process.env.VUE_APP_APP_NAME}`);
